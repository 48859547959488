<!--[批量生成卡密/批量注册用户]共用组件-->
<!--1.显示日志-->
<!--2.下载日志记录-->
<template>
    <div style="margin-bottom: 10px">
        <el-button style="margin-right: 10px">{{langArr['html.el-button.refresh']}}</el-button>
        <el-tag>Tips:{{prop.Describe}}</el-tag>
    </div>

    <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" :label="langArr['html.el-table-column.id']" width="180" />
        <el-table-column prop="file" :label="langArr['html.el-table-column.file']"  />
        <el-table-column label="Tools" width="180">
            <template #default="scope">
                <el-button @click="downloadFile(scope.row.file)" type="danger">{{langArr['html.el-button.downloadFile']}}</el-button>
            </template>
        </el-table-column>
    </el-table>

    <div style="margin-top: 10px">
        <PaginationTableData
            :TableData="prop.LogDataArr"
            @updateTableData = 'updateTableData'

        />
    </div>
</template>

<script setup>
import {defineProps, defineEmits, ref} from "vue";
import PaginationTableData from "./PaginationTableData";
const prop = defineProps(['LogDataArr','Describe']);
const emit = defineEmits(['downloadFile']);
import lang from '../lang/index';
const langArr = ref(lang.getLang('components','DownLoadLog'));

/**
 * 用户点击下载记录按钮
 * @param file 文件名
 */
const downloadFile = (file) => emit('downloadFile',file)

let tableData = ref([]);
const updateTableData = (data) => {
    tableData.value.length = 0;
    tableData.value = data;
}

</script>

<style scoped>

</style>