<!--分页组件[配合表格组件使用]-->
<template>
    <el-pagination
        v-model:page-size="config.size"
        :page-sizes="[20,100,200,300,500]"

        v-model:currentPage="config.page"
        @size-change="configChange"

        layout="total,sizes, prev, pager, next"
        :total="config.total"
        @current-change="updateTableData"
    />
</template>

<script setup>
import {reactive, defineProps, defineEmits, watch, onUpdated} from "vue";
import paging from "../utils/paging";
const prop = defineProps(['TableData']);
const emit = defineEmits(['updateTableData']);

/**
 * 分页配置
 * @type {UnwrapNestedRefs<{total: number, size: number, page: number}>}
 */
let config = reactive({
    page:1,
    size:20,
    total:0,
});

/**
 * 监听父组件传来的数据源
 */
watch(prop.TableData,()=>{
    initPaging();
});

/**
 * 分页配置变动
 */
const configChange = () => {
    initPaging();
}

/**
 * 重新初始化分页类库
 */
const initPaging = () => {
    config.total = prop.TableData.length;
    config.page = 1;
    paging.init(prop.TableData,config.size);
    paging.setPage(config.page);
    paging.setSize(config.size);
    updateTableData();
}

/**
 * 通知父组件表格数据已更新
 */
const updateTableData = () => {
    paging.setPage(config.page);
    let data = paging.getPageData();
    emit('updateTableData',data);
}

let isInit = false;
onUpdated(()=>{
    if (prop.TableData != null && !isInit){
        initPaging();
        isInit = true;
    }else if(prop.TableData.length != config.total){
        initPaging();
    }
});

</script>

<style scoped>

</style>